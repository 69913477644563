import * as React from 'react'
export const MydraDuoLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 260 65"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#895AD7"
        d="m35.022 50.232 2.245-2.794c8.443 5.151 18.921 4.206 25.283-3.712 6.91-8.602 4.179-19.489-4.423-26.4L37.328.628 26.671 13.896l4.746 1.64-2.206 2.749c-8.444-5.152-18.95-4.172-25.31 3.746-6.912 8.602-4.152 19.455 4.45 26.366l20.671 16.602 10.63-13.238-4.633-1.53h.003Zm-11.457-25.14c4.289-5.34 12.1-6.192 17.44-1.903 5.341 4.289 6.193 12.097 1.904 17.44-4.288 5.341-12.1 6.194-17.44 1.905-5.34-4.29-6.193-12.1-1.904-17.441Z"
      />
      <path
        fill="currentColor"
        d="M115.96 16.148c-2.908 0-8.111.828-10.571 6.313-1.87-4.306-5.124-6.313-10.21-6.313-4.723 0-8.334 2.282-10.187 6.43H82.99l2.261-5.344h-7.56v33.823h7.467V33.565c0-8.224 3.956-9.953 7.272-9.953 4.237 0 6.475 2.389 6.475 6.911v20.534h7.467V33.492c0-6.464 2.54-9.88 7.344-9.88 4.19 0 6.403 2.389 6.403 6.911v20.534h7.468V30.162c0-8.907-4.238-14.014-11.626-14.014ZM156.477 17.234l-8.543 22.338h-2.278l-8.544-22.338h-8.286l11.29 30.561h6.1l-2.093 6.42c-.925 2.973-2.478 4.358-4.891 4.358-2.412 0-3.653-1.306-3.653-3.581v-.911h-7.251v.91c0 6.238 4.076 9.96 10.901 9.96 6.196 0 9.581-3.134 12.485-11.564l13.052-36.16h-8.285l-.004.007ZM191.983 22.331c-2.457-3.993-6.44-6.186-11.254-6.186-4.485 0-8.599 1.698-11.592 4.78-3.196 3.293-4.887 7.863-4.887 13.218 0 5.354 1.691 9.925 4.887 13.217 2.993 3.083 7.107 4.78 11.592 4.78 5.031 0 8.914-1.993 11.254-5.766v4.68h7.468V0h-7.468v22.331Zm.361 11.812c0 6.385-4.058 10.674-10.096 10.674-6.039 0-10.097-4.29-10.097-10.674 0-6.386 4.058-10.678 10.097-10.678 6.038 0 10.096 4.289 10.096 10.678ZM211.905 24.04V17.23h-7.467v33.823h7.467V36.026c0-7.581 3.323-11.11 10.458-11.11h1.271v-8.698h-.69c-5.221 0-9.21 2.838-11.039 7.821ZM252.534 17.234v4.68c-2.34-3.773-6.223-5.766-11.255-5.766-4.484 0-8.598 1.698-11.591 4.78-3.196 3.293-4.887 7.863-4.887 13.218 0 5.354 1.691 9.925 4.887 13.217 2.993 3.083 7.107 4.78 11.591 4.78 5.138 0 9.08-2.079 11.403-6.01h2.003l-2.254 4.924h7.571V17.234h-7.468Zm.361 16.912c0 6.385-4.059 10.674-10.097 10.674-6.038 0-10.096-4.29-10.096-10.674 0-6.386 4.058-10.678 10.096-10.678s10.097 4.289 10.097 10.678Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h260.003v65H0z" />
      </clipPath>
    </defs>
  </svg>
)
