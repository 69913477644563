'use client'

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { FragmentStyleContainer } from '@/app/components/FragmentStyleContainer'
import { Button } from '@/app/modules/shared/components'
import { useTranslations } from 'next-intl'

export type AlertModalProps = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean
  onClose: (result: boolean) => Promise<void>
  message: string
}

export default function AlertModal({
  isOpen,
  onClose,
  message,
  ...props
}: AlertModalProps) {
  const t = useTranslations('AlertModal')

  return (
    <Transition.Root appear show={isOpen} as={Fragment}>
      <Dialog
        {...props}
        as="div"
        className={`relative z-[40] font-sans ${props.className ?? ''} `}
        onClose={() => onClose(false)}
      >
        <FragmentStyleContainer>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-modal-bg" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-[#292525a6]">
            <div className="flex items-center justify-center min-h-full sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col items-center gap-4 p-10 bg-white rounded-2xl max-w-prose md:items-start">
                  <div className="text-lg md:text-2xl font-title">
                    {message}
                  </div>

                  <div className="flex flex-col items-end justify-between w-full gap-4 mt-4 md:flex-row">
                    <span className="font-sans text-sm font-semibold text-mydra-black">
                      {t('need-help')}{' '}
                      <a
                        href="mailto:support@mydra.io"
                        className="font-sans text-sm font-semibold underline text-mydra-black"
                      >
                        {t('contact-us')}
                      </a>
                    </span>

                    <Button color="black" onClick={() => onClose(false)}>
                      {t('close')}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </FragmentStyleContainer>
      </Dialog>
    </Transition.Root>
  )
}
