import Link from 'next/link'
import React from 'react'

// TODO: replace with SecondaryButton
export const OutlineButton = ({ onClick, children, ...props }) => {
  const ComponentTag = props.href ? Link : 'button'

  return (
    <ComponentTag
      {...props}
      className={`p-2 text-black rounded-[14px] outline outline-1 outline-[--lines] text-center min-h-12 px-4 py-1 leading-8 ${
        props.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-mydra-gray'
      } ${props.className ?? ''}`}
      onClick={onClick}
    >
      {children}
    </ComponentTag>
  )
}
